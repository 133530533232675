import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import Content, { HTMLContent } from '../components/layout/Content';

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className='flex-grow'>
      <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
        <div className='py-6 text-center'>
          <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
            about
          </h1>
          <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
            {title}
          </h2>
          <h3 className='mt-4 mb-8 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
            distilled #energy insights
          </h3>
          <PageContent
            className='py-2 pb-6 px-5 bg-white shadow-2xl rounded-3xl'
            content={content}
          />
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
